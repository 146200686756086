@import '../app.scss';

.camera-list-container {
    width: 1600px;
    overflow-y: auto;
}

.background-landing {
    background: url(../../images/box.jpg);
    @include width-height(100%, 100%);
    @include cover-background;
}

.camera-box {
    height: 223px;
    width: 385px;
    border-radius: 7px;
    border: 7px solid #c4c4c4
}

.main-layout {
    overflow-x: auto !important;
}
