@import '../app.scss';

.signin-container {
  .left-wrapper {
    background: url(../../images/leftLogin.png);
    @include width-height(100%, 100vh);
    @include cover-background;
    position: relative;
  }

  .right-wrapper {
    @include flex-center;
    padding: 0 100px;
    .title {
      color: $GRAY;
    }
  }
}
