/**** Page Loading ****/
.page-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1299;
  &:after {
    content: '';
    display: block;
    position: fixed;
    width: 100%;
    height: 100%;
    background: $LIGHT_WHITE;
    z-index: -1;
  }
}

/**** Icon Select ****/
.select-with-icon {
  .btn-filter {
    padding: 0 2rem;
    min-width: 228px;
    justify-content: space-between;
    border: 0.5px solid #00afbb;
    border-radius: 8px;
    color: #00afbb;
    span {
      margin: 0 1rem;
    }
  }
}

/**** Landing Modal ****/
.MuiModal-root {
  .modal-content {
    border-radius: $RADIUS_LARGE;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 400px;
    padding: 15px;
    background: $WHITE;
    .title {
      font-weight: bold;
      color: $PRIMARY_COLOR;
      font-size: $FONT_SIZE_LARGE;
    }
    .MuiButton-textSizeMedium {
      height: 0px;
    }
  }
}

/**** Comment ****/
.comment-container {
  .comment-content {
    border: 1px solid $PRIMARY_COLOR;
    max-height: 200px;
    height: 100%;
    overflow-y: auto;
  }
  .comment-send {
    border: 1px solid $PRIMARY_COLOR;
    .icon-send {
      color: $PRIMARY_COLOR;
      align-self: flex-end;
    }
  }
  .item-content {
    word-break: break-all;
    white-space: pre-line;
  }
}
/**** Data Grid Table ****/
.table-using-mui {
  .MuiDataGrid-columnHeaders {
    background-color: $PRIMARY_COLOR;
    .MuiDataGrid-columnHeaderTitle {
      font-weight: bold;
    }
  }
  .MuiDataGrid-row,
  .MuiDataGrid-root .MuiDataGrid-cell,
  .rendering-zone {
    max-height: none !important;
  }
  .MuiDataGrid-root .MuiDataGrid-window {
    position: relative !important;
  }
  .MuiDataGrid-root .MuiDataGrid-viewport {
    max-height: none !important;
  }
  .MuiDataGrid-cell {
    white-space: normal !important;
  }
  .table-data-grid {
    .MuiDataGrid-iconButtonContainer {
      visibility: visible;
      width: 0 !important;
    }
    .MuiDataGrid-columnHeaderTitle {
      line-height: 1.43rem;
      white-space: normal;
      color: $WHITE;
    }
    .MuiDataGrid-columnHeaderTitleContainerContent {
      width: 70%;
    }
    .header-override {
      .MuiDataGrid-columnHeaderTitleContainerContent {
        width: unset;
      }
    }
  }
}

/**** Search Input ****/
.search-input {
  .MuiOutlinedInput-root {
    border-radius: $RADIUS_LARGE;
  }
}

/**** Antd Table ****/
.ant-table-pagination.ant-pagination {
  margin: 16px;
}
.ant-table-thead {
  .ant-table-cell {
    background-color: $LIGHT_BLUE;
  }
}
.ant-btn-link,
.ant-btn-link:hover,
.ant-btn-link[disabled] {
  border: 1px solid $OUTLINE;
  margin-right: 3px;
}
.text-field-require {
  label.Mui-disabled::after {
    content: '';
  }
  label::after {
    content: ' *';
    color: red;
  }
}
