@import '../app.scss';

.reset-container {
  .left-wrapper {
    background: url(../../images/leftLogin.png);
    @include width-height(100%, 100vh);
    @include cover-background;
    position: relative;
    &:before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: $PRIMARY_COLOR;
      opacity: 50%;
    }
  }

  .right-wrapper {
    @include flex-center;
    padding: 0 100px;
    .title {
      color: $GRAY;
    }
  }
}
