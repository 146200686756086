@import '../app.scss';

.sidebar-container {
  position: fixed;
  height: 100vh;
  .left-wrapper {
    margin-top: $HEADER_HEIGHT;
    box-sizing: border-box;

    .sidebar-list {
      display: flex;
      flex-direction: column;
      gap: 15px;
      width: 100%;
      max-width: 360px;
      padding: 20px;
    }

    .list-item {
      height: 52px;
      border-radius: 8px;
      color: $DARK_BLUE;

      &.Mui-selected .MuiTypography-root {
        font-weight: bold;
        color: $WHITE;
      }

      &.Mui-selected .MuiSvgIcon-root {
        color: $WHITE;
      }

      &.Mui-selected {
        background-color: $PRIMARY_COLOR;

        &:hover {
          background-color: $PRIMARY_LIGHT_COLOR;
        }
      }

      .primary {
        color: $DARK_BLUE;
      }
    }
  }

  .right-wrapper {
    background-color: $BODY_COLOR;

    .sticky-button {
      position: sticky;
      top: 300px;
      margin-left: 5px;
      opacity: 0.8;
    }
  }
}
