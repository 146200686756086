@import '../app.scss';

.global-call-container {
  .breadcrumb-text {
    color: $DARK_BLUE;
    &-list {
      color: $PRIMARY_COLOR;
      text-decoration: none;
    }
  }
  .global-call {
    &-stream {
      background-color: $LIGHT_BLACK;
      padding: 23px;
      border-radius: $RADIUS_NORMAL;
      padding-bottom: 0;
      &-main {
        width: 100%;
        height: 683px;
        video {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      &-participants {
        min-height: 150px;
        width: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        video {
          width: 100%;
          height: 100%;
          object-fit: fill;
        }
        &-info {
          position: absolute;
          bottom: 0;
          .MuiBox-root {
            background: $BLUR_BLACK;
            color: $WHITE;
            font-size: $FONT_SIZE_SMALL;
            padding: 4px;
            height: 25px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
      &-control {
        color: white;
        background: $BLUR_DARK_BLUE;
        width: 60%;
        margin: 0 auto;
        .controller-volume,
        .controller-mic {
          margin: 23px;
          padding: 8px;
          background: $BLUR_GRAY;
          border-radius: 8px;
          border: 1px solid rgba(75, 85, 99, 0.5);
          height: 50px;
        }
        .controller-volume {
          width: 120px;
        }
      }
      .stream-participants-wrapper {
        overflow: auto;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
        &::-webkit-scrollbar {
          display: none; /*Chrome, Safari and Opera */
        }
      }
    }
  }
}
