@import '../../app.scss';

.group-select-container {
  .form-control {
    margin: 12px;
    width: 300px;
  }

  .select-all-text {
    font-weight: 500;
  }

  .selected-all {
    background-color: 'rgba(0, 0, 0, 0.08)';
    &:hover {
      background-color: 'rgba(0, 0, 0, 0.08)';
    }
  }
}

.monitor-container {
  .input-number-no-arrow {
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
  .group-name {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    width: 300px;
    max-height: 50px;
  }
}
