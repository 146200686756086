@import '../app.scss';

.layout-container {
  .page-title {
    margin-bottom: $SPACING;
  }
  .container {
  }
  .layout-wrapper {
    display: flex;
    width: 100%;
    min-height: 100vh;
    .main-layout {
      width: 100%;
      margin-top: $HEADER_HEIGHT;
      background-color: $BODY_COLOR;
      flex-grow: 1;
      padding: $SPACING;
      margin-left: 250px;
      overflow: hidden;

      .phone-container {
        width: 150px;
        height: 150px;
        background: $GRAY;
        border-radius: 150px 0 0 0;
        -moz-border-radius: 150px 0 0 0;
        -webkit-border-radius: 150px 0 0 0;
      }

      .phone-ring {
        animation: shake 0.5s;
        animation-iteration-count: infinite;
      }
    }
  }
  .MuiToolbar-root {
    min-height: $HEADER_HEIGHT;
  }
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}
