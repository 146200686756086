@import '../app.scss';

.history-details-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  height: 95%;
  background-image: url(../../images/background-monitoring.png);
  @include cover-background;
  padding: $SPACING;
  .box-details {
    &-title,
    &-using {
      border-radius: 40px;
      font-weight: 600;
      font-size: 24px;
      padding: 0 25px;
    }
    &-title {
      border: 3px solid $PRIMARY_COLOR;
      background: $PRIMARY_COLOR_BLUR;
      color: $WHITE;
      white-space: nowrap;
      max-width: 50%;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    &-using {
      background-color: $WHITE;
      color: $PRIMARY_COLOR;
    }
  }
  .box-service-wrapper {
    border-radius: $RADIUS_LARGE;
    outline: 3px solid $WHITE;
    padding: 1px;

    &-zoom {
      position: relative;
      width: 100%;
      padding-top: 56.25%;
    }

    .box-service {
      &-zoom {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: black;
        border-radius: $RADIUS_LARGE;
      }

      video {
        object-fit: contain;
        border-radius: $RADIUS_LARGE;
        &::-webkit-media-controls-fullscreen-button {
          display: none;
        }
      }
    }
  }

  #history-comment {
    max-height: 98px;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .button-back {
    background-color: $WHITE;
    border: 1px solid $PRIMARY_COLOR;
    color: $DARK_BLUE;
    font-weight: 700;
    font-size: 16px;
    &:hover {
      background: $WHITE;
    }
  }
}
