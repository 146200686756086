.monitor-detail-container {
  .group-name {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    width: 300px;
    max-height: 50px;
  }
}
