@import '../../variables.scss';

.monitor-list-container {
  .icon {
    color: $DARK_BLUE;
  }
}

.dialog-container {
  .warning-icon {
    color: $WARNING_ICON;
    font-size: 120px;
  }
}
