@font-face {
    font-family: 'Noto Sans JP';
    src: url(../font/NotoSansJP-Thin.otf);
    font-weight: 100;
}

@font-face {
    font-family: 'Noto Sans JP';
    src: url(../font/NotoSansJP-Regular.otf);
    font-weight: 400;
}

@font-face {
    font-family: 'Noto Sans JP';
    src: url(../font/NotoSansJP-Medium.otf);
    font-weight: 500;
}

@font-face {
    font-family: 'Noto Sans JP';
    src: url(../font/NotoSansJP-Bold.otf);
    font-weight: 700;
}

@font-face {
    font-family: 'Noto Sans JP';
    src: url(../font/NotoSansJP-Black.otf);
    font-weight: 900;
}

