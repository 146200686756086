@import '../app.scss';
.dashboard-container {
  width: 100%;
  height: 85vh;

  .background-landing {
      background: url(../../images/background-dashboard.png);
      @include width-height(100%, 100%);
      @include cover-background;
  }
}
  
